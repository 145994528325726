<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <span class="text-h4">
            Control Center
          </span>
        </v-col>
      </v-row>
      <div class="mt-4">
        <v-row>
          <v-col sm="12">
            <v-card>
              <v-card-title>
                <v-row>
                  <v-col>
                    <span class="text-h5">Users</span>
                  </v-col>
                  <v-col align="right">
                    <v-btn color="primary" @click="doAddUser()" :disabled="userRole !== 'admin'">
                      <font-awesome-icon size="lg" :icon="['fas', 'plus']" fixed-width></font-awesome-icon>
                      <span class="ml-2">Add</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-data-table :headers="tables.users.headers" :items="tables.users.data" :loading="tables.users.isLoading" @click:row="doUpdateUser">
                  <template v-slot:item.tempPassword="{ item }">
                    <v-btn @click="doResetPassword($event, item)" color="primary" :disabled="item.tempPassword">Reset password</v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
<!--          <v-col cols="12" sm="6">-->
<!--            <v-card>-->
<!--              <v-card-title>-->
<!--                <span class="text-h5">Other</span>-->
<!--              </v-card-title>-->
<!--            </v-card>-->
<!--          </v-col>-->
        </v-row>
      </div>
    </v-container>
    <admin-control-add-user ref="adminAddUser" @doLoad="doLoad"></admin-control-add-user>
    <admin-control-update-user ref="adminUpdateUser" @doLoad="doLoad"></admin-control-update-user>
  </div>
</template>

<script>
import AdminControlAddUser from '@/views/admin/control/addUser.vue'
import AdminControlUpdateUser from '@/views/admin/control/updateUser.vue'

export default {
  name: 'admin-controls',
  components: { AdminControlUpdateUser, AdminControlAddUser },
  data: () => ({
    tables: {
      users: {
        isLoading: false,
        headers: [
          {
            text: 'User',
            align: 'start',
            sortable: true,
            value: 'fullName'
          },
          {
            text: 'Role',
            value: 'roleKey'
          },
          {
            text: 'Email',
            value: 'email'
          },
          {
            text: 'Phone Number',
            value: 'phoneNumber'
          },
          {
            text: 'Password',
            value: 'tempPassword'
          }
        ],
        data: []
      }
    },
    userRole: null
  }),
  methods: {
    doAddUser () {
      this.$refs.adminAddUser.doOpen()
    },
    doUpdateUser (event) {
      if (this.userRole === 'admin') {
        this.$refs.adminUpdateUser.doOpen(event.id)
      }
    },
    doLoad () {
      this.doLoadUsers()
    },
    doLoadUsers () {
      this.tables.users.isLoading = true

      this.$services.user.doGetAll().then(users => {
        this.tables.users.data = users
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.tables.users.isLoading = false
      })
    },
    doResetPassword (event, row) {
      if (event) {
        event.stopPropagation()
      }

      this.tables.users.isLoading = true

      this.$services.user.doResetPassword(row.id).then(_ => {
        this.$root.$emit('showWarning', `${row.fullName} will receive a temporary password via text message.`)
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.tables.users.isLoading = false
      })
    }
  },
  mounted () {
    this.doLoad()
    this.userRole = this.$store.getters.doGetRole
  }
}
</script>

<style scoped>

</style>
